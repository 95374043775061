
import StoreInfo from '@/components/Sensor/storeInfo.vue';
import Sensor from '@/components/Sensor/sensor.vue';
import store from '@/store/';
import stores from '@/services/stores';
import bussinessHours from '@/components/Sensor/bussinessHours.vue';
import ChangeLog from "@/components/Sensor/changeLog.vue"

export default {
  components: { StoreInfo, Sensor, bussinessHours, ChangeLog },
  data() {
    return {
      tab: 'storeInfo',
      allStoresData: [],
      selectedStore: {},
      loading: true,
      visible: false,
      isChangeLogOpen: false,
    };
  },

  async mounted() {
    this.visible = true;
    // this.getStoreType()
    this.getAllStores();
    // var data = await resources.getCountries()
  },
  methods: {
    openChangeLog() {
      this.isChangeLogOpen = true; 
     
  },
  closeModal() {
      this.isChangeLogOpen = false;
    },
    tabHandler(tab) {
      this.tab = tab;
    },
    getAllStores() {
      this.visible = true;
      this.loading = true;
      const {id} = this.$route.query;
      if(!id){
        this.visible = false;
        this.loading = false;
        return
      }
      // this.$refs.sensor.visible = true;
      // this.$refs.sensor.loading = true;
      stores
        .getStores()
        .then(response => {
          this.allStoresData = response?.data?.response?.stores.reverse();
          const selectedStore = this.allStoresData.find(
            item => item._id === this.$route.query.id
          );
          this.editStoreDialog(selectedStore);
          this.visible = false;
          this.loading = false;
          // this.$refs.sensor.visible = false;
          // this.$refs.sensor.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.visible = false;
          this.loading = false;
          // this.$refs.sensor.visible = false;
          // this.$refs.sensor.loading = false;
          if (error?.response?.data?.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: 'red'
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            });
          }
        });
    },
    // getStoreType() {
    //   this.visible = true;
    //   this.loading = true;
    //   stores
    //     .getStoreTypes()
    //     .then(response => {
    //       this.allStoresData = response.data.response.stores;
    //       console.log(this.allStoresData, 'all store data')
    //       this.visible = false;
    //       this.loading = false;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.visible = false;
    //       this.loading = false;
    //       if (error.response.data.message) {
    //         this.$q.notify({
    //           message: error.response.data.message,
    //           color: "red"
    //         });
    //       } else {
    //         this.$q.notify({
    //           message: this.$store.state.user.translate
    //             .something_went_wrong,
    //           color: "red"
    //         });
    //       }
    //     });
    // },
    addNewStoreDialog() {
      // console.log("open");
      this.openModel = true;
    },
    editStoreDialog(store) {
      this.selectedStore = store;
    },
    async updateStore() {
      this.getAllStores();
      this.openModel = false;
    },
    openStoreModel() {
      this.selectedStore = null;
      this.openModel = true;
    },

    deleteStore(store) {
      this.selectedStore = store;
      this.confirm = true;
    },
    confirmDeleteStore() {
      stores
        .deleteStore(this.selectedStore._id)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.removed_successfully,
            color: 'green'
          });
          this.updateStore();

          let { profile } = store.state['user'];
          data.email = profile?.email;
          Vue.prototype?.$mixpanel?.track(
            'webapp_sensor_deleteStore',
            this.selectedStore
          );
        })
        .catch(error => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: 'red'
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            });
          }
        });
      this.confirm = false;
    }
  }
};
